<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card class="text-center">

      <h1 class="text-center ">Добавяне на услуга</h1>

      <v-card-text>
        <v-list two-line class="overflow-y-auto" style="height: 55vh">
          <template v-for="(service,i) in services">
            <v-list-item :key="service.ID">
              <v-list-item-action>
                <v-checkbox :value="service" v-model="selectedServices" multiple></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ service.Name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="i+1" inset></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-spacer></v-spacer>
        <v-btn color="red" @click="cancel" text>Отказ</v-btn>
        <v-btn color="#31A4C1" @click="confirm" text>Добави</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddService",
  data: () => ({
    dialog: false,
    message: "",
    resolve: null,
    reject: null,
    services: [],
    selectedServices: []
  }),
  computed: {
    status: {
      get() {
        return this.$store.state.status
      },
      set(val) {
        this.$store.commit('setStatus', val)
      }
    },
    orders: {
      get() {
        return this.$store.state.pendingOrders
      }
    },
    user: {
      get() {
        return this.$store.state.user
      }
    },
  },
  methods: {
    open(services) {
      this.dialog = true
      this.fetchServices()
      this.selectedServices = services
      return new Promise(((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      }))
    },
    confirm() {
      this.dialog = false
      this.resolve(this.selectedServices)
    },
    cancel() {
      this.dialog = false
      this.resolve(false)
    },
    fetchServices() {
      let self = this;
      if(!this.services.length){
        this.get(`/partner/${this.user.partner_id}/group/${this.user.group_id}/goods`).then(r => {
          self.services = r.services
        })
      }

    },
  }
}
</script>

<style scoped>

</style>